import {axios} from "@/lib/axios";
import {TAdminInitialValues} from "../../pages/staff/Team.page";

import {
  IAPIDeleteResponseDeleteStaff,
  IAPIGetAdminStatsMessages,
  IAPIGetAdminStatsProfiles,
  IAPIGetAdminStatsResponse,
  IAPIGetStaffTotalOnlines,
  IAPIPatchResponseResendEmail,
  IAPIPatchResponseUpdateStaff,
  IAPIPostResponseAddStaff,
  IAPIResponseGetAllStaffs,
} from "../../types";

export const getAllStaffs = (
  {
    limit,
    page
  } : {
    limit?: number;
    page?: number;
  } = {}
): Promise<IAPIResponseGetAllStaffs> => {
  const params = limit && page ? { limit, page } : undefined;
  return axios.get("/auth-s/bo/api/v1/accounts/staffs", { params });
};

export const getTotalOnlines = (): Promise<IAPIGetStaffTotalOnlines> => {
  return axios.get("/auth-s/bo/api/v1/accounts/staffs/total-onlines");
}

export const getAdminStatsDashboard = (datas: {
  adminId: string;
  startDate?: string;
  endDate?: string;
}): Promise<IAPIGetAdminStatsResponse> => {
  const convertToISOString = (date?: string): string | undefined => {
    if (!date) return undefined;
    return isNaN(Date.parse(date)) ? undefined : new Date(date).toISOString();
  };

  const startDateISO = convertToISOString(datas.startDate);
  const endDateISO = convertToISOString(datas.endDate);

  return axios.get(`/profile-s/bo/api/v1/profiles/${datas.adminId}/validations/stats`, {
    params: {
      startDate: startDateISO,
      endDate: endDateISO,
    },
  });
};

export const getMessagesStatsDashboard = (datas: {
  adminId: string;
  startDate?: string;
  endDate?: string;
}): Promise<IAPIGetAdminStatsResponse> => {
  const convertToISOString = (date?: string): string | undefined => {
    if (!date) return undefined;
    return isNaN(Date.parse(date)) ? undefined : new Date(date).toISOString();
  };

  const startDateISO = convertToISOString(datas.startDate);
  const endDateISO = convertToISOString(datas.endDate);

  return axios.get(`/chat-s/bo/api/v1/messages/${datas.adminId}/messages/stats`, {
    params: {
      startDate: startDateISO,
      endDate: endDateISO,
    },
  });
};

export const getAdminStatsProfiles = (datas: {
  adminId: string;
  page?: number;
  limit?: number;
  status?: "VALIDATED" | "REJECTED" | "PENDING";
  startDate?: string;
  endDate?: string;
  search?: string;
}): Promise<IAPIGetAdminStatsProfiles> => {
  const convertToISOString = (date?: string): string | undefined => {
    if (!date) return undefined;
    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime()) ? parsedDate.toISOString() : undefined;
  };

  const startDateISO = convertToISOString(datas.startDate);
  const endDateISO = convertToISOString(datas.endDate);

  return axios.get(`/profile-s/bo/api/v1/profiles/${datas.adminId}/validations`, {
    params: {
      page: datas.page,
      limit: datas.limit,
      status: datas.status,
      startDate: startDateISO,
      endDate: endDateISO,
      searchTerm: datas.search,
    },
  });
};

export const getAdminStatsMessages = (datas: {
  adminId: string;
  page?: number;
  limit?: number;
  filter?: "VALIDATED" | "REJECTED" | "VALIDATED,REJECTED";
  startDate?: string;
  endDate?: string;
  search?: string;
}): Promise<IAPIGetAdminStatsMessages> => {
  const convertToISOString = (date?: string): string | undefined => {
    if (!date) return undefined;
    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime()) ? parsedDate.toISOString() : undefined;
  };

  const startDateISO = convertToISOString(datas.startDate);
  const endDateISO = convertToISOString(datas.endDate);

  return axios.get(`/chat-s/bo/api/v1/messages/${datas.adminId}/messages`, {
    params: {
      page: datas.page,
      limit: datas.limit,
      status: datas.filter || "VALIDATED,REJECTED",
      startDate: startDateISO,
      endDate: endDateISO,
      search: datas.search,
    },
  });
};



export const addStaff = (
  data: TAdminInitialValues
): Promise<IAPIPostResponseAddStaff> => {
  return axios.post("/auth-s/bo/api/v1/accounts/staffs", {
    ...data,
  });
};

export const updateStaff = (
  id: string,
  data: TAdminInitialValues
): Promise<IAPIPatchResponseUpdateStaff> => {
  return axios.patch(`/auth-s/bo/api/v1/accounts/staffs/${id}`, {
    ...data,
  });
};

export const deleteStaff = (
  id: string
): Promise<IAPIDeleteResponseDeleteStaff> => {
  return axios.delete(`/auth-s/bo/api/v1/accounts/staffs/${id}`);
};

export const resendEmail = (
    id: string
): Promise<IAPIPatchResponseResendEmail> => {
  return axios.patch(`/auth-s/bo/api/v1/accounts/${id}/resend-activation-email`);
}


export const updateStaffPassword  = ({
  id,
  password,
}: {
  id: string;
  password: string;
}): Promise<{
  code: string;
  message: string;
}> => {
  return axios.patch(`/auth-s/bo/api/v1/accounts/staffs/${id}/change-password`, {
    password,
  });
};

export const getModerationStatusModerator = (): Promise<{data: boolean, message: string}> => {
  return axios.get("/auth-s/bo/api/v1/accounts/staffs/status");
};

export const toggleStaffStatus = (status: boolean): any => {
  return axios.patch("/auth-s/bo/api/v1/accounts/staffs/toggle-status",{
    status
  });
}
