import { createAsyncThunk } from "@reduxjs/toolkit";
import to from "await-to-js";
import { AxiosError } from "axios";

import { TReducerError } from "@/types/app";

import {
  IAPIFilterProfiles,
  IApiGet,
  IAPIGetAdminStatsMessages,
  IAPIGetDeletedProfiles,
  IAPIGetMembersDatas,
  IAPIGetModeratedMessages,
  IAPIGetProfileValidations,
  IAPIGetRefusalsProfiles,
  IAPIGetReports,
  IAPIGetSubscriptions,
  IAPIPatchReportMessage,
  IAPIPostMember,
  IAPIResponseGetAllPendingProfiles,
  IAPIResponseGetAllProfiles,
  TApprouveAccountToSend,
  TGender,
  TInfosHealthPayload,
  TInfosReligiousAspectPayload,
  TInfosReligiousPracticePayload,
  TMaritalStatus,
  TMemberProfile,
  TPagination,
  TProfileDetails,
  TProfilePendingDetails,
  TProfilesFilter,
  TProfileUpdate,
  TReportsFilter,
} from "@/features/backoffice/types";

import handlingError from "@/utils/handlingError";

import {
  addMemberAccount,
  approuveAccount,
  banProfile,
  deleteAccount,
  disApprouveAccount,
  editMemberAccount,
  getAllProfiles,
  getAllReports,
  getAllSubscriptions,
  getDeletedProfiles,
  getFilteredProfiles,
  getFilteredReports,
  getMemberDetails,
  getMembersDatas,
  getModeratedMessages,
  getNbUnhandledReports,
  getPendingProfiles,
  getProfileMember,
  getProfileValidations,
  manuallyConfirmEmail,
  markAsHandled,
  refreshStatus,
  resetMemberPassword,
  restoreMemberAccount,
  sendReportMessage,
  switchProfileToFree,
  toggleAccountActiveness,
  unbanProfile,
  updateMember,
  updateMemberHealth,
  updateMemberReligousAspect,
  updateMemberReligousPractice,
  validateAllReviews,
} from "@/features/backoffice/api";
import { TMemberInitialValues } from "@/features/backoffice/pages/members/ProfileManagement.page";
import { Toastify } from "@/lib/toast";
import { store } from "@/store";

export const getMemberDetailsAsync = createAsyncThunk<
  IApiGet<TProfileDetails>,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/getMemberDetails", async (memberId, { rejectWithValue }) => {
  const [error, data] = await to<IApiGet<TProfileDetails>, AxiosError<any>>(
    getMemberDetails(memberId)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getBannedMemberDetailsAsync = createAsyncThunk<
  IApiGet<TProfileDetails>,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/getMemberDetails", async (memberId, { rejectWithValue }) => {
  const [error, data] = await to<IApiGet<TProfileDetails>, AxiosError<any>>(
    getMemberDetails(memberId)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getListAccountsMember = createAsyncThunk<
  IAPIResponseGetAllProfiles,
  { pagination?: TPagination & TProfilesFilter; currentURL: string },
  {
    rejectValue: TReducerError;
  }
>(
  "members/getAllProfiles",
  async ({ pagination, currentURL }, { rejectWithValue }) => {
    const [error, data] = await to<IAPIResponseGetAllProfiles, AxiosError<any>>(
      getAllProfiles({ ...pagination }, currentURL)
    );

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const getFilteredProfilesAsync = createAsyncThunk<
  IAPIResponseGetAllProfiles,
  (TProfilesFilter & TPagination) | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getFilteredProfiles", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIResponseGetAllProfiles, AxiosError<any>>(
    getFilteredProfiles({ ...filters })
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getListPendingAccountsMember = createAsyncThunk<
  IAPIResponseGetAllPendingProfiles,
  {
    status: string;
    limit: number;
    page: number;
    searchTerm: string | undefined;
  },
  {
    rejectValue: TReducerError;
  }
>("members/getPendingProfiles", async (_params, { rejectWithValue }) => {
  const [error, data] = await to<
    IAPIResponseGetAllPendingProfiles,
    AxiosError<any>
  >(
    getPendingProfiles({
      status: _params.status,
      limit: _params.limit,
      page: _params.page,
      searchTerm: _params.searchTerm,
    })
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getListDeletedAccountsMember = createAsyncThunk<
  IAPIGetDeletedProfiles,
  ({ searchTerm: string | undefined } & TPagination) | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getDeletedProfiles", async (pagination, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetDeletedProfiles, AxiosError<any>>(
    getDeletedProfiles(pagination)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const approuveAccountAsync = createAsyncThunk<
  IApiGet<TProfileDetails>,
  { id: string; dataToSend: TApprouveAccountToSend },
  {
    rejectValue: TReducerError;
  }
>(
  "members/approuveAccount",
  async ({ id, dataToSend }, { rejectWithValue }) => {
    const [error, data] = await to<IApiGet<TProfileDetails>, AxiosError<any>>(
      approuveAccount(id, dataToSend)
    );

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const disApprouveAccountAsync = createAsyncThunk<
  TMemberProfile,
  { id: string; message: string },
  {
    rejectValue: TReducerError;
  }
>("members/disApprouveAccount", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    disApprouveAccount(datas.id, datas.message)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const deleteAccountAsync = createAsyncThunk<
  {
    data: string;
    message: string;
  },
  { id: string; message: string; password: string },
  {
    rejectValue: TReducerError;
  }
>("members/deleteAccount", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      data: string;
      message: string;
    },
    AxiosError<any>
  >(deleteAccount(datas.id, datas.password, datas.message));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  console.log(data);

  return data;
});

export const toggleAccountActivenessAsync = createAsyncThunk<
  any,
  { id: string; reason?: string },
  {
    rejectValue: TReducerError;
  }
>("members/toggleAccountActiveness", async (params, { rejectWithValue }) => {
  const [error, data] = await to<any, AxiosError<any>>(
    toggleAccountActiveness(params.id, params.reason)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const banProfileAsync = createAsyncThunk<
  {
    data: boolean;
    message: string;
  },
  { id: string; reason?: string },
  {
    rejectValue: TReducerError;
  }
>("members/ban", async (params, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      data: boolean;
      message: string;
    },
    AxiosError<any>
  >(banProfile(params.id, params.reason));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const unbanProfileAsync = createAsyncThunk<
  {
    data: boolean;
    message: string;
  },
  string,
  {
    rejectValue: TReducerError;
  }
>("members/unban", async (id, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      data: boolean;
      message: string;
    },
    AxiosError<any>
  >(unbanProfile(id));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const switchProfileToFreeAsync = createAsyncThunk<
  {
    data: TMemberProfile;
    message: string;
  },
  { id: string },
  {
    rejectValue: TReducerError;
  }
>("members/switch-account-to-free", async (params, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      data: TMemberProfile;
      message: string;
    },
    AxiosError<any>
  >(switchProfileToFree(params.id));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getAllReportsAsync = createAsyncThunk<
  IAPIGetReports,
  TPagination | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getAllReports", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetReports, AxiosError<any>>(
    getAllReports(filters)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getFilteredReportsAsync = createAsyncThunk<
  IAPIGetReports,
  (TReportsFilter & TPagination) | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getFilteredReports", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetReports, AxiosError<any>>(
    getFilteredReports(filters)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getNbUnhandledReportsAsync = createAsyncThunk<
  {
    data: number;
    message: string;
  },
  undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getNbUnhandledReports", async (_filters, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      data: number;
      message: string;
    },
    AxiosError<any>
  >(getNbUnhandledReports());

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getListSubscriptionsAsync = createAsyncThunk<
  IAPIGetSubscriptions,
  ({ term: string | undefined } & TPagination) | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getAllSubscriptions", async (pagination, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetSubscriptions, AxiosError<any>>(
    getAllSubscriptions(pagination)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const sendReportMessageAsync = createAsyncThunk<
  IAPIPatchReportMessage,
  {
    reportId: string;
    receiver: string;
    subject: string;
    message: string;
  },
  {
    rejectValue: TReducerError;
  }
>("members/sendReportMessage", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchReportMessage, AxiosError<any>>(
    sendReportMessage({ ...datas })
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const marckAsHandledAsync = createAsyncThunk<
  any,
  { id: string },
  {
    rejectValue: TReducerError;
  }
>("members/markAsHandled", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<any, AxiosError<any>>(markAsHandled(datas.id));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getMembersDatasAsync = createAsyncThunk<
  IAPIFilterProfiles,
  | {
      country?: string;
      citizenship?: string;
      homeCountry?: string;
      maritalStatus?: TMaritalStatus;
      gender?: TGender;
      max_age?: number;
      min_age?: number;
    }
  | TPagination
  | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getMembersDatas", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIFilterProfiles, AxiosError<any>>(
    getMembersDatas(filters as any)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const resetMemberPasswordAsync = createAsyncThunk<
  TMemberProfile,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/resetMemberPassword", async (memberId, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    resetMemberPassword(memberId)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const restoreMemberAccountAsync = createAsyncThunk<
  TMemberProfile,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/restoreMemberAccount", async (memberId, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    restoreMemberAccount(memberId)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getProfileMemberAsync = createAsyncThunk<
  TMemberProfile,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/getProfileMember", async (memberId, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    getProfileMember(memberId)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const addMemberAsync = createAsyncThunk<
  IAPIPostMember,
  TMemberInitialValues,
  {
    rejectValue: TReducerError;
  }
>("members/addMember", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostMember, AxiosError<any>>(
    addMemberAccount(values)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const editMemberAsync = createAsyncThunk<
  TProfileDetails,
  TMemberInitialValues,
  {
    rejectValue: TReducerError;
  }
>("members/editMember", async (values, { rejectWithValue }) => {
  const [error, data] = await to<
    { data: TProfileDetails; message: string },
    AxiosError<any>
  >(editMemberAccount(values));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data?.data;
});

export const getProfileValidationsAsync = createAsyncThunk<
  IAPIGetRefusalsProfiles,
  | {
      admin?: string;
      member?: string;
      searchModerator?: string;
      status?: "REJECTED" | "VALIDATED" | "REJECTED,VALIDATED";
    }
  | TPagination
  | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getProfileValidations", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetRefusalsProfiles, AxiosError<any>>(
    getProfileValidations(filters)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getModeratedMessagesAsync = createAsyncThunk<
  IAPIGetAdminStatsMessages,
  | {
      admin?: string;
      member?: string;
      status?: "REJECTED" | "VALIDATED" | "REJECTED,VALIDATED";
      searchModerator?: string;
    }
  | TPagination
  | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getModeratedMessages", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetAdminStatsMessages, AxiosError<any>>(
    getModeratedMessages(filters)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateMemberInfosAsync = createAsyncThunk<
  IApiGet<TProfileDetails>,
  {
    id: string;
    data: TProfileUpdate;
  },
  {
    rejectValue: TReducerError;
  }
>("members/updateMemberInfos", async (infos, { rejectWithValue }) => {
  const [error, data] = await to<IApiGet<TProfileDetails>, AxiosError<any>>(
    updateMember(infos.id, infos.data)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateMemberInfosReligiousAspectAsync = createAsyncThunk<
  TProfileDetails,
  { id: string; gender: TGender; data: TInfosReligiousAspectPayload },
  {
    rejectValue: TReducerError;
  }
>(
  "members/updateMemberInfosReligousAspect",
  async (infos, { rejectWithValue }) => {
    const [error, data] = await to<TProfileDetails, AxiosError<any>>(
      updateMemberReligousAspect(infos.id, infos.gender, infos.data)
    );

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const updateMemberInfosReligiousPracticeAsync = createAsyncThunk<
  TProfileDetails,
  { id: string; data: TInfosReligiousPracticePayload },
  {
    rejectValue: TReducerError;
  }
>(
  "members/updateMemberInfosReligousPractice",
  async (infos, { rejectWithValue }) => {
    const [error, data] = await to<TProfileDetails, AxiosError<any>>(
      updateMemberReligousPractice(infos.id, infos.data)
    );

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const updateMemberInfosHealthAsync = createAsyncThunk<
  TProfileDetails,
  { id: string; data: TInfosHealthPayload },
  {
    rejectValue: TReducerError;
  }
>("members/updateMemberInfosHealth", async (infos, { rejectWithValue }) => {
  const [error, data] = await to<TProfileDetails, AxiosError<any>>(
    updateMemberHealth(infos.id, infos.data)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const validateAllReviewsAsync = createAsyncThunk<
  IApiGet<TProfileDetails>,
  { id: string },
  {
    rejectValue: TReducerError;
  }
>("members/validateAllReviewsAsync", async (params, { rejectWithValue }) => {
  const [error, data] = await to<any, AxiosError<any>>(
    validateAllReviews(params.id)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

type TApprouveOrRejectSectionArg = {
  id: string;
  data: TApprouveAccountToSend;
  setProfile:
    | React.Dispatch<React.SetStateAction<TProfileDetails | null>>
    | undefined;
  isRejected: boolean;
};
export const triggerApprouveOrRejectSectionAsync = async ({
  id,
  data,
  setProfile,
  isRejected,
}: TApprouveOrRejectSectionArg) => {
  const dispatch = store.dispatch;
  const res = await dispatch(approuveAccountAsync({ id, dataToSend: data }));

  if (approuveAccountAsync.fulfilled.match(res)) {
    setProfile?.(res.payload.data);

    Toastify(
      `Section ${isRejected ? "rejetée" : "approuvée"} avec succès.`,
      "success",
      3000
    );
  } else if (approuveAccountAsync.rejected.match(res)) {
    if (res.payload) {
      Toastify(`${res.payload.message}`, "error", 3000);
    } else {
      Toastify(`${res.error.message}`, "error", 3000);
    }
  }
};

export const manuallyConfirmEmailAsync = createAsyncThunk<
  boolean,
  { id: string },
  {
    rejectValue: TReducerError;
  }
>("members/manuallyConfirmEmail", async (params, { rejectWithValue }) => {
  const [error, data] = await to<boolean, AxiosError<any>>(
    manuallyConfirmEmail(params.id)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const refreshStatusAsync = createAsyncThunk<
  {
    data: TProfilePendingDetails;
    message: string;
  },
  { id: string },
  {
    rejectValue: TReducerError;
  }
>("members/refreshStatus", async (params, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      data: TProfilePendingDetails;
      message: string;
    },
    AxiosError<any>
  >(refreshStatus(params.id));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});
