import { TMemberInitialValues } from "../../pages/members/ProfileManagement.page";

import isEmpty from "@/utils/isEmpty";
import {
  IAPIFilterProfiles,
  IApiGet,
  IAPIGetDeletedProfiles,
  IAPIGetProfileValidations,
  IAPIPostMember,
  IAPIResponseGetAllPendingProfiles,
  IAPIResponseGetAllProfiles,
  TApprouveAccountToSend,
  TGender,
  TInfosHealthPayload,
  TInfosReligiousPracticePayload,
  TMaritalStatus,
  TMemberProfile,
  TProfileDetails,
  TProfilesFilter,
} from "../../types";
import { axios } from "@/lib/axios";
import useCurrentLocation from "@/utils/getCurrentLocation";

const getIsBannedParam = (currentURL: string) => {
  return currentURL === "/members/profiles-banishment"
    ? { isBanned: true }
    : {};
};

export const getMemberDetails = (
  memberId: string
): Promise<IApiGet<TProfileDetails>> => {
  return axios.get(`/profile-s/bo/api/v1/profiles/${memberId}`);
};

export const getAllProfiles = (
  {
    limit,
    page,
    ...filters
  }: {
    limit?: number;
    page?: number;
  } & TProfilesFilter = {},
  currentURL: string
): Promise<IAPIResponseGetAllProfiles> => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return axios.get("/profile-s/bo/api/v1/profiles/", {
    params: {
      limit,
      page,
      ...(filters.searchTerm
        ? emailRegex.test(filters.searchTerm)
          ? { email: filters.searchTerm }
          : { searchTerm: filters.searchTerm }
        : {}),
      ...filters,
      ...getIsBannedParam(currentURL),
    },
  });
};

// export const getAllProfiles = ({
//   limit,
//   page,
//   ...filters
// }: {
//   limit?: number;
//   page?: number;
// } & TProfilesFilter = {}, currentURL: string): Promise<IAPIResponseGetAllProfiles> => {
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return axios.get('/profile-s/bo/api/v1/profiles/', {
//     params: {
//       limit,
//       page,
//       ...(filters.searchTerm
//         ? emailRegex.test(filters.searchTerm)
//           ? { email: filters.searchTerm }
//           : { searchTerm: filters.searchTerm }
//         : {}),
//       ...filters,
//       ...getIsBannedParam(currentURL),
//     },
//   });
// };

export const getFilteredProfiles = ({
  member_id,
  username,
  email,
  gender,
  is_verified,
  has_subscription,
  limit,
  page,
}: {
  member_id?: string;
  username?: string;
  email?: string;
  gender?: TGender;
  is_verified?: boolean;
  has_subscription?: boolean;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIResponseGetAllProfiles> => {
  return axios.get("/profile-s/bo/api/v1/members/filtered/accounts", {
    params: {
      member_id,
      username,
      email,
      gender,
      is_verified,
      has_subscription,
      limit,
      page,
    },
  });
};

export const getMembersDatas = ({
  country,
  citizenship,
  homeCountry,
  maritalStatus,
  gender,
  maxAge,
  minAge,
  limit,
  page,
}: {
  country?: string;
  citizenship?: string;
  homeCountry?: string;
  maritalStatus?: TMaritalStatus;
  gender?: TGender;
  maxAge?: number;
  minAge?: number;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIFilterProfiles> => {
  return axios.get("/profile-s/bo/api/v1/profiles/", {
    params: {
      country,
      citizenship,
      homeCountry,
      maritalStatus,
      gender,
      maxAge,
      minAge,
      limit,
      page,
    },
  });
};

export const getPendingProfiles = ({
  searchTerm,
  status,
  limit,
  page,
}: {
  searchTerm?: string;
  status?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIResponseGetAllPendingProfiles> => {
  return axios.get("/profile-s/bo/api/v1/profiles/awaiting-validations", {
    params: {
      searchTerm,
      status,
      limit,
      page,
    },
  });
};

export const getDeletedProfiles = ({
  searchTerm,
  limit,
  page,
}: {
  searchTerm?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetDeletedProfiles> => {
  return axios.get("/profile-s/bo/api/v1/profiles/account-deleted", {
    params: {
      searchTerm,
      limit,
      page,
    },
  });
};

export const approuveAccount = (
  id: string,
  dataToSend: TApprouveAccountToSend
): Promise<IApiGet<TProfileDetails>> => {
  return axios.patch(`/profile-s/bo/api/v1/profiles/${id}/reviews`, {
    ...dataToSend,
  });
};

export const disApprouveAccount = (
  id: string,
  message: string
): Promise<TMemberProfile> => {
  return axios.patch(`/profile-s/bo/api/v1/changes-requests/${id}`, {
    is_accepted: false,
    comment: message,
  });
};

export const deleteAccount = (
  id: string,
  password: string,
  message?: string
): Promise<{
  data: string;
  message: string;
}> => {
  return axios.delete(`/auth-s/bo/api/v1/accounts/${id}/by-admin`, {
    data: {
      password,
      reason: message + " ",
    },
  });
};

export const toggleAccountActiveness = (
  id: string,
  reason?: string
): Promise<{
  code: "string";
  message: "string";
}> => {
  return axios.patch(`/profile-s/bo/api/v1/profiles/${id}/toggle-activation`, {
    reason,
  });
};

export const banProfile = (
  id: string,
  reason?: string | undefined
): Promise<{ data: boolean; message: "string" }> => {
  return axios.patch(`/auth-s/bo/api/v1/accounts/${id}/toggle-banishment`, {
    isBanned: true,
    reason,
  });
};

export const unbanProfile = (
  id: string
): Promise<{
  data: boolean;
  message: "string";
}> => {
  return axios.patch(`/auth-s/bo/api/v1/accounts/${id}/toggle-banishment`, {
    isBanned: false,
  });
};

export const switchProfileToFree = (
  id: string
): Promise<{
  data: TMemberProfile;
  message: string;
}> => {
  return axios.patch(`/profile-s/bo/api/v1/profiles/${id}/toggle-free`);
};

export const resetMemberPassword = (
  memberId: string
): Promise<TMemberProfile> => {
  return axios.patch(
    `/profile-s/bo/api/v1/members/${memberId}/force-password-reset`
  );
};

export const restoreMemberAccount = (
  memberId: string
): Promise<TMemberProfile> => {
  return axios.patch(`/auth-s/bo/api/v1/accounts/${memberId}/restore-account`);
};

export const getProfileMember = (memberId: string): Promise<TMemberProfile> => {
  return axios.get(`/profile-s/bo/api/v1/profiles/${memberId}`);
};

// export const addMemberAccount = (
//   data: TMemberInitialValues
// ): Promise<IAPIPostMember> => {
//   return profilAxios.post(`/members`, {
//     ...data,
//   });
// };

export const addMemberAccount = (
  data: TMemberInitialValues
): Promise<IAPIPostMember> => {
  return axios.post(
    `/auth-s/bo/api/v1/accounts/create-free-account`,
    {
      ...data,
      username: isEmpty(data.username) ? undefined : data.username,
      age: isEmpty(data.birthDate) ? undefined : data.birthDate,
    },
    {
      headers: {
        source: "WEB",
      },
    }
  );
};

export const editMemberAccount = (
  data: TMemberInitialValues
): Promise<{ data: TProfileDetails; message: string }> => {
  const { id, ...restData } = data;
  return axios.patch(
    `/auth-s/bo/api/v1/accounts/${data.id}/update-member-by-admin`,
    {
      ...restData,
    }
  );
};

export const getProfileValidations = ({
  admin,
  member,
  status,
  limit,
  page,
  searchModerator,
}: {
  admin?: string;
  member?: string;
  status?: "REJECTED" | "VALIDATED" | "REJECTED,VALIDATED";
  limit?: number;
  page?: number;
  searchModerator?: string;
} = {}): Promise<IAPIGetProfileValidations> => {
  return axios.get(`/profile-s/bo/api/v1/profiles/${member}/resufals`, {
    params: {
      // admin,
      status,
      limit,
      page,
      ...(searchModerator !== "" && { searchModerator }),
    },
  });
};

export const forcePasswordReset = (
  memberId: string,
  password: string
): Promise<TMemberProfile> => {
  return axios.patch(
    `/auth-s/bo/api/v1/accounts/members/${memberId}/change-password`,
    {
      password,
    }
  );
};

export const updateMember = (
  memberId: string,
  data: any
): Promise<IApiGet<TProfileDetails>> => {
  return axios.patch(`/profile-s/bo/api/v1/profiles/${memberId}`, data);
};

export const updateMemberReligousAspect = (
  memberId: string,
  gender: TGender,
  data: any
): Promise<TProfileDetails> => {
  return axios.patch(
    `/profile-s/bo/api/v1/members/profile/${gender}/${memberId}/religious-aspect-infos`,
    data
  );
};

export const updateMemberReligousPractice = (
  memberId: string,
  data: TInfosReligiousPracticePayload
): Promise<TProfileDetails> => {
  return axios.patch(
    `/profile-s/bo/api/v1/members/profile/${memberId}/religious-practice-infos`,
    data
  );
};

export const updateMemberHealth = (
  memberId: string,
  data: TInfosHealthPayload
): Promise<TProfileDetails> => {
  return axios.patch(
    `/profile-s/bo/api/v1/members/profile/${memberId}/health-infos`,
    data
  );
};

export const validateAllReviews = (memberId: string): Promise<any> => {
  return axios.patch(
    `/profile-s/bo/api/v1/profiles/${memberId}/reviews/validate/all`
  );
};

export const manuallyConfirmEmail = (memberId: string): Promise<any> => {
  return axios.patch(
    `/auth-s/bo/api/v1/accounts/${memberId}/manually-confirm-email`
  );
};

export const refreshStatus = (profileId: string): Promise<any> => {
  return axios.patch(
    `/profile-s/bo/api/v1/profiles/${profileId}/refresh-status`
  );
};
